body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

header {
  padding: 20px;
}

header nav ul {
  list-style: none;
}

header nav ul li {
  display: inline;
}

header nav ul li a {
  text-decoration: none;
}

section {
  padding: 20px;
  margin: 20px 0;
}

img {
  pointer-events: none
}
